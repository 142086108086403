import Lottie from 'react-lottie';
import animationData from './loading.json'

export default function Loading() {
  const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
  
  return (
    <div style={{ 
      marginLeft: -15,
      width: 'fit-content',
     }}>
      <Lottie 
	    options={defaultOptions}
        height={100}
        width={100}
      />
    </div>
  );
}