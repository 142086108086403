import { useState } from 'react';
import { GRAY_400, type Message } from './App';
import { ResumeLink } from './components/ResumeLink';
import { EmailLink } from './components/EmailLink';

export const useDefaultMessages: (sendPrompt: (_: string) => void) => Message[] = (
  sendPrompt
) => [
  {
    type: 'prompt',
    content: 'tell me a bit about Vincent Liaw',
  },
  {
    type: 'response',
    content: (
      <>
        <p>
          Senior Fullstack Engineer with the ability to deliver across the
          stack, with a bias for action, strong product sense, and a broad skill
          set. Passionate about human-computer, computer-computer, and
          human-human interaction (roughly in that order)
        </p>
        <p>
          Previously, I’ve worked at Databricks, LinkedIn, UCSD (as a TA and
          research developer), and Team Solomid.
        </p>
        <p>
          To find out more, simply type in a prompt, or head to one of these
          quick links:
        </p>
        <ul>
          <li>
            <ResumeLink />
          </li>
          <li>
            Email: <EmailLink text="vincentcliaw@gmail.com" /> (or{' '}
            <span
              style={{
                color: GRAY_400,
                cursor: 'pointer',
                borderBottom: '1px solid',
                fontWeight: 'bold',
              }}
              tabIndex={0}
              onClick={async () => {
                await navigator.clipboard.writeText('vincentcliaw@gmail.com');
              }}
            >
              copy
            </span>
            )
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/vincentliaw"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>{' '}
            /{' '}
            <a
              href="https://github.com/liawesomesaucer"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub
            </a>{' '}
            /{' '}
            <a
              href="https://dribbble.com/liawesomesaucer"
              target="_blank"
              rel="noopener noreferrer"
            >
              Dribbble
            </a>
          </li>
        </ul>
        <p>
          <i>Try these sample prompts:</i>
        </p>

        <PromptButton
          prompt={'What are your skills?'}
          sendPrompt={sendPrompt}
        />
        <PromptButton
          prompt={'Tell me about a recent project at Databricks'}
          sendPrompt={sendPrompt}
        />
        <PromptButton
          prompt={'How is this site made?'}
          sendPrompt={sendPrompt}
        />
      </>
    ),
  },
];

const PromptButton = ({
  prompt,
  sendPrompt,
}: {
  prompt: string;
  sendPrompt: (_: string) => void;
}) => {
  const [clicked, setClicked] = useState(false);

  return (
    <button
      style={
        {
          marginBottom: 8,
            display: 'block',
        ...(clicked
          ? {
              // color: GRAY_400,
              // backgroundColor: GRAY_100,
              // borderColor: GRAY_400,
              // cursor: 'initial',
            }
          : undefined)
        }
      }
      onClick={() => {
        // if (clicked) {
          // return
        // }
        setClicked(true);
        sendPrompt(prompt);
      }}
    >
      {clicked ? '✔ ' : null}
      {prompt}
    </button>
  );
};
