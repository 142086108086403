import { ReactElement, useCallback, useState } from 'react';
import { useDefaultMessages } from './useDefaultMessages';
import { ResumeLink } from './components/ResumeLink';
import { EmailLink } from './components/EmailLink';
import Loading from './components/Loading';

const BOT_NAME = 'Lliawma';

const BORDER_RADIUS = 100;
export const GRAY_100 = '#E4EEEF';
export const GRAY_400 = '#9696A8';
export const GRAY_600 = '#5E5E6A';

function PromptBubble({ prompt }: { prompt: string | ReactElement }) {
  return (
    <p
      style={{
        borderRadius: BORDER_RADIUS,
        backgroundColor: GRAY_100,
        padding: '8px 20px',
        alignSelf: 'flex-end',
      }}
    >
      {prompt}
    </p>
  );
}

function BotResponse({
  name = BOT_NAME,
  response, // rich text
}: {
  name?: String;
  response: String | ReactElement;
}) {
  return (
    <div>
      <i>{name}</i>
      <p>{response}</p>
    </div>
  );
}

type MessageType = 'prompt' | 'response';
export type Message = {
  type: MessageType;
  content: string | ReactElement;
};

function App() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [prompt, setPrompt] = useState<string>('');

  const [error, setError] = useState<string | undefined>();
  const [loading, setIsLoading] = useState<boolean>(false);

  const addMessage = useCallback(
    (message: Message) =>
      setMessages((existingMessages: Message[]) => [
        ...existingMessages,
        message,
      ]),
    []
  );


  const handleSendPrompt = useCallback(
    async (prompt: string) => {
      setError(undefined);
      setIsLoading(true);
      addMessage({
        type: 'prompt',
        content: prompt,
      });

      const response = await fetch('/api/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt }),
      });

      setIsLoading(false)

      if (!response.ok) {
        setError(`Oh no! An error occurred 😿 ${response.statusText} `)
        setPrompt(prompt);
      } else {
        const data = await response.json();
        setPrompt('');
        addMessage({
          type: 'response',
          content: data.response,
        });
      }
    },
    [addMessage]
  );

  const defaultMessages = useDefaultMessages(handleSendPrompt);

  return (
    <div className="App">
      <div
        style={{
          maxWidth: 1000,
          margin: 'auto',
          paddingLeft: 15,
          paddingRight: 15,
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{ display: 'flex', flexDirection: 'column', paddingTop: 40 }}
        >
          <h1 style={{ fontWeight: 300, fontSize: 54, letterSpacing: 3 }}>
            Vincent Liaw
          </h1>
          {[...defaultMessages, ...messages].map(({ type, content }) => {
            if (type === 'prompt') {
              return <PromptBubble prompt={content} />;
            } else return <BotResponse response={content} />;
          })}
          {loading && <Loading />}
          {Boolean(error?.length) && (
          <div>
            <span style={{ color: '#EC2B2E'}}>{error}</span>
            <span
              style={{
                color: GRAY_400,
                cursor: 'pointer',
                borderBottom: '1px solid',
                fontWeight: 'bold',
              }}
              tabIndex={0}
              onClick={() => handleSendPrompt(prompt)
              }
            >Try again
            </span>
            </div>)}
        </div>

        <div>
          <form
            onSubmit={(e) => e.preventDefault()}
            style={{
              margin: '40px auto 0 auto',
              marginTop: 40,
              maxWidth: 900,
              display: 'flex',
              position: 'relative',
            }}
          >
            <input
              placeholder={`Message ${BOT_NAME}`}
              style={{
                borderRadius: BORDER_RADIUS,
                backgroundColor: GRAY_100,
                padding: '12px 20px',
                fontStyle: 'italic',
                letterSpacing: 0.5,
                outline: 'none',
                border: 'none',
                width: '100%',
              }}
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
            ></input>
            <button
              style={{
                position: 'absolute',
                top: 7,
                right: 10,
                padding: '3px 40px',
              }}
              disabled={!Boolean(prompt.length)}
              onClick={() => handleSendPrompt(prompt)}
            >
              Send
            </button>
          </form>
          <p style={{ textAlign: 'center', color: GRAY_400, fontSize: 14 }}>
            This is a bot, and it can make mistakes. To talk to a real human
            (Vincent), download my <ResumeLink text="resume" /> or reach out via{' '}
            <EmailLink text="email" />
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
